/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import { RotatingLines } from "react-loader-spinner";
import useUserStore from "../useUserStore";
import "./Connexion.css";

function Reinitialisationmotdepasse() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  const { token } = useParams();

  const username = useUserStore((state) => state.username)

  useEffect(() => {
    if (username!=='') {
      navigate('/mesprojets')
    }
  })

  const onSubmit = (formdata) => {
    setLoading(true)
    setErrorMessage('')
    // eslint-disable-next-line no-param-reassign
    formdata = {email:formdata.email, password:formdata.password, token}
    axios.post('https://cdgdev-local.azurewebsites.net/v1/Restore/usingToken', formdata)
      .then((res) => {
        console.log(res)
        setLoading(false)
        navigate('/connexion')
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
        setErrorMessage("Erreur: Veuillez réessayer.")
      });
  };


  return (
    <main className="connexion-container">
      {errorMessage || loading? 
        <div className="error-message-container">
          {errorMessage? <h2 className="error-message">{errorMessage}</h2>:null}
          {loading? <div className="loading-container">
                      <RotatingLines
                        strokeColor="#f64c72"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="40"
                      />
                      <h2 className="error-message">Réinitialisation du mot de passe en cours...</h2>
                    </div>
                    :
                    null}
        </div>
      : null}
      <div className="connexion-sub-container">
        <div className="connexion-title-container">
          <h1 className="connexion-title">RÉINITIALISER LE MOT DE PASSE</h1>
        </div>
        <form
          className="connexion-form"
          action="TO BE ADDED"
          method="post"
          onSubmit={handleSubmit(onSubmit)}
        >
          <input
            placeholder="COURRIEL"
            type="text"
            {...register("email", {
              required:
                "Veuillez entrer l'adresse courriel associée à votre compte",
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Veuillez entrez une adresse courriel valide",
              },
            })}
            aria-invalid={errors.email ? "true" : "false"}
            className="connexion-input"
          />
          {errors.email && (
            <p role="alert" className="error-message">
              {errors.email?.message}
            </p>
          )}
          <input
            placeholder="NOUVEAU MOT DE PASSE"
            type="password"
            {...register("password", {
              required: "Veuillez choisir un nouveau mot de passe",
              minLength: {
                value: 8,
                message:
                  "Votre mot de passe doit contenir 8 caractères au minimum",
              },
              maxLength: {
                value: 20,
                message:
                  "Votre mot de passe doit contenir 20 caractères au maximum",
              },
            })}
            aria-invalid={errors.password ? "true" : "false"}
            className="connexion-input"
          />
          {errors.password && (
            <p role="alert" className="error-message">
              {errors.password?.message}
            </p>
          )}
          <input
            placeholder="CONFIRMER LE MOT DE PASSE"
            type="password"
            {...register("mdpconfirm", {
              required: "Veuillez confirmer votre nouveau mot de passe",
              validate: {
                matchesPreviousPassword: (value) => {
                  const { password } = getValues();
                  return (
                    password === value || "Les mots de passe ne sont pas identiques"
                  );
                },
              },
            })}
            aria-invalid={errors.mdpconfirm ? "true" : "false"}
            className="connexion-input"
          />
          {errors.mdpconfirm && (
            <p role="alert" className="error-message">
              {errors.mdpconfirm?.message}
            </p>
          )}
          <button type="submit" className="connect-btn">
            Réinitialiser
          </button>
        </form>
      </div>
    </main>
  );
}

export default Reinitialisationmotdepasse;
