function handleFile(file, stateUpdate){
    const extension = file.name.split(".").pop();
  
    if (extension === "json" || extension === "geojson"){
      const reader = new FileReader();
      
         reader.onload = function (event) {
          const jsonObj = JSON.parse(event.target.result);
       
          stateUpdate(jsonObj)
         } 
          reader.readAsText(file);
    }
}

function convertAccents(string){
  return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export { handleFile, convertAccents };