/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState }from 'react';
import { useNavigate } from 'react-router-dom';
import maplibregl from 'maplibre-gl';
import Map from 'react-map-gl';
import { RotatingLines } from 'react-loader-spinner';
import axios from 'axios';
import useUserStore from '../useUserStore';
import searchIcon from '../images/search.svg';

function Projets() {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const [projets, setProjets] = useState([]);
  const [connected, setConnected] = useState(false);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [displayCount, setDisplayCount] = useState(3);
  const [displaySearchData, setDisplaySearchData] = useState(false)

  const username = useUserStore((state) => state.username)

  useEffect(() => {
    if (username !== '') {
      setConnected(true);
    }
    useUserStore.setState({fontColor:'#000000', bgColor:'#ffffff'})
    setLoading(true)
    setErrorMessage('')
    axios.get('https://cdgdev-local.azurewebsites.net/v1/Project/all')
    .then((res) => {
      setErrorMessage('')
      setLoading(false)
      setProjets(res.data.data)
    })
    .catch((error) => {
      console.log(error, error.response.data.message)
      setLoading(false)
      setErrorMessage("Erreur dans la récupération des projets. Veuillez rafraîchir la page.")
    });
  }, []);

  const searchItem = (item) => {
    if (!item.length) {
      setDataToDisplay([])
      setDisplaySearchData(false)
    } else {
      setDisplaySearchData(true)
      const filteredElements = projets.filter((proj) => proj.name.toLowerCase().startsWith(item))
      setDataToDisplay([...filteredElements])
    }
  }

  const goToProject = (projet) => {
    navigate(`/${projet.projectPID}`)
  }

  const seeMyProjects = () => {
    navigate('/mesprojets');
  }

  const loadMoreProjects = () => {
    setDisplayCount(displayCount + 3);
  };

  return (
    <main className="connexion-container">
      {errorMessage || loading? 
        <div className="error-message-container">
          {errorMessage? <h2 className="error-message">{errorMessage}</h2>:null}
          {loading? <div className="loading-container">
                      <RotatingLines
                        strokeColor="#f64c72"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="40"
                      />
                      <h2 className="error-message">Chargement des projets en cours...</h2>
                    </div>
                    :
                    null}
        </div>
      : null}
      {connected?<button className="see-my-projects-link-btn" type='button' onClick={seeMyProjects}>VOIR MES PROJETS</button> : null}
      <div className="projets-search-bar-container">
        <div className="search-bar-input-container">
          <input className="search-bar-input" placeholder="CHERCHER" onChange={(e) => searchItem(e.target.value)}/>
          <img src={searchIcon} alt='search icon' className="search-image"/>
        </div>
        <div className="search-results-container" style={{display: dataToDisplay.length>0?'flex':'none'}}>
          {dataToDisplay.length>0?
            dataToDisplay?.map((data, index) => <button key={index} className="search-result" type='button' onClick={() => goToProject(data)}>{data.name}</button>)
            :
            null
          }
        </div>
      </div>
      <div className="projets-container">
        <div className="projets-title-container">
          <div className="projets-title-sub-container">
            <h1 className="projets-title">PROJETS PUBLICS</h1>
          </div>
        </div>
        {displaySearchData?
        <div className="display-projects-container">
          {projets?.length !==0 && dataToDisplay?.length!==0 ?
              dataToDisplay?.map((projet, index) => 
                                        <div key={index} className="single-project-container">
                                          <div className="single-project-map-container">
                                            <Map  mapLib={maplibregl}
                                              initialViewState={{
                                                longitude: projet.center.lng,
                                                latitude: projet.center.lat,
                                                zoom: 10,
                                                minZoom: 10,
                                              }}
                                              mapStyle='https://api.maptiler.com/maps/dataviz/style.json?key=6lKsa6bzFsGKREIufiPE'
                                              attributionControl= {false}
                                              style={{borderRadius:'2rem'}}
                                            />
                                          </div>
                                          <button className="single-project-btn" type='button' onClick={() => goToProject(projet)}>{projet.name}</button>
                                        </div>
                                        )
          :
          null
          }
        </div>
        :
        <div className="display-projects-container">
          {projets?.length !==0 && dataToDisplay?.length===0 ?
            projets?.slice(0, displayCount).map((projet, index) => 
                                      <div key={index} className="single-project-container">
                                        <div className="single-project-map-container">
                                          <Map  mapLib={maplibregl}
                                            initialViewState={{
                                              longitude: projet.center.lng,
                                              latitude: projet.center.lat,
                                              zoom: 10,
                                              minZoom: 10,
                                            }}
                                            mapStyle='https://api.maptiler.com/maps/dataviz/style.json?key=6lKsa6bzFsGKREIufiPE'
                                            attributionControl= {false}
                                            style={{borderRadius:'2rem'}}
                                          />
                                        </div>
                                        <button className="single-project-btn" type='button' onClick={() => goToProject(projet)}>{projet.name}</button>
                                      </div>
                                      )
          :
          <div className="no-proj-box">
            <h2 className="no-proj-text">AUCUN PROJET</h2>
          </div>
          }
      </div>
      }
      {displayCount < projets.length && (
        <button onClick={loadMoreProjects} type='button' className="see-more-projects-btn">VOIR PLUS DE PROJETS</button>
      )}
    </div>
    </main>
  );
}

export default Projets