/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import maplibregl from 'maplibre-gl';
import axios from 'axios';
import { RotatingLines } from "react-loader-spinner";
import useUserStore from "../useUserStore";
import searchIcon from '../images/search.svg';
import planPNG from '../images/plan_nobg.png';
import k1 from '../images/kiosques/k1.PNG';
import k2 from '../images/kiosques/k2.PNG';
import k6 from '../images/kiosques/k6.PNG';
import k7 from '../images/kiosques/k7.PNG';
import k8 from '../images/kiosques/k8.PNG';
import k9 from '../images/kiosques/k9.PNG';
import k10 from '../images/kiosques/k10.PNG';
import k11 from '../images/kiosques/k11.PNG';
import k14 from '../images/kiosques/k14.PNG';
import k15 from '../images/kiosques/k15.PNG';
import k17 from '../images/kiosques/k17.PNG';
import k20 from '../images/kiosques/k20.PNG';
import k21 from '../images/kiosques/k21.PNG';
import k22 from '../images/kiosques/k22.PNG';
import k23 from '../images/kiosques/k23.PNG';
import k24 from '../images/kiosques/k24.PNG';
import k26 from '../images/kiosques/k26.PNG';
import k28 from '../images/kiosques/k28.PNG';
import k30 from '../images/kiosques/k30.PNG';
import k32 from '../images/kiosques/k32.PNG';
import k33 from '../images/kiosques/k33.PNG';
import k34 from '../images/kiosques/k34.PNG';
import k35 from '../images/kiosques/k35.PNG';
import k36 from '../images/kiosques/k36.PNG';
import k37 from '../images/kiosques/k37.PNG';
import k38 from '../images/kiosques/k38.PNG';
import k39 from '../images/kiosques/k39.PNG';
import k40 from '../images/kiosques/k40.PNG';
import k41 from '../images/kiosques/k41.PNG';
import k42 from '../images/kiosques/k42.PNG';
import k43 from '../images/kiosques/k43.PNG';
import k44 from '../images/kiosques/k44.PNG';
import k45 from '../images/kiosques/k45.PNG';
import k46 from '../images/kiosques/k46.PNG';
import k48 from '../images/kiosques/k48.PNG';
import k49 from '../images/kiosques/k49.PNG';
import k50 from '../images/kiosques/k50.PNG';
import k502 from '../images/kiosques/k50-2.PNG';
import k503 from '../images/kiosques/k50-3.PNG';
import k51 from '../images/kiosques/k51.PNG';
import k52 from '../images/kiosques/k52.PNG';
import k53 from '../images/kiosques/k53.PNG';
import k532 from '../images/kiosques/k53-2.PNG';
import k54 from '../images/kiosques/k54.PNG';
import k542 from '../images/kiosques/k54-2.PNG';
import k55 from '../images/kiosques/k55.PNG';
import k56 from '../images/kiosques/k56.PNG';
import k57 from '../images/kiosques/k57.PNG';
import k58 from '../images/kiosques/k58.PNG';
import k59 from '../images/kiosques/k59.PNG';
import k60 from '../images/kiosques/k60.PNG';
import k61 from '../images/kiosques/k61.PNG';
import k62 from '../images/kiosques/k62.PNG';
import k63 from '../images/kiosques/k63.PNG';
import k64 from '../images/kiosques/k64.PNG';
import k65 from '../images/kiosques/k65.PNG';
import k66 from '../images/kiosques/k66.PNG';
import k68 from '../images/kiosques/k68.PNG';
import k69 from '../images/kiosques/k69.PNG';
import k70 from '../images/kiosques/k70.PNG';
import k72 from '../images/kiosques/k72.PNG';
import k75 from '../images/kiosques/k75.PNG';
import k76 from '../images/kiosques/k76.PNG';
import k77 from '../images/kiosques/k77.PNG';
import k78 from '../images/kiosques/k78.PNG';
import k79 from '../images/kiosques/k79.PNG';
import k80 from '../images/kiosques/k80.PNG';
import k81 from '../images/kiosques/k81.PNG';
import k82 from '../images/kiosques/k82.PNG';
import k83 from '../images/kiosques/k83.PNG';
import k84 from '../images/kiosques/k84.PNG';
import k85 from '../images/kiosques/k85.PNG';
import k86 from '../images/kiosques/k86.PNG';
import k87 from '../images/kiosques/k87.PNG';
import k89 from '../images/kiosques/k89.PNG';
import k91 from '../images/kiosques/k91.PNG';
import k92 from '../images/kiosques/k92.PNG';
import k93 from '../images/kiosques/k93.PNG';
import k94 from '../images/kiosques/k94.PNG';
import k100 from '../images/kiosques/k100.PNG';
import k101 from '../images/kiosques/k101.PNG';
import k102 from '../images/kiosques/k102.PNG';
import k103 from '../images/kiosques/k103.PNG';
import k104 from '../images/kiosques/k104.PNG';
import k105 from '../images/kiosques/k105.PNG';
import k106 from '../images/kiosques/k106.PNG';

// remplacer toute la logique de photos

function Projet() {
  const navigate = useNavigate();

  const [projet, setProjet] = useState(null);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [connected, setConnected] = useState(false);
  const [markersRefs, setMarkersRefs] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [photoArray, setPhotoArray] = useState([])

  const mapContainer = useRef();
  const map = useRef();
  const inputRef = useRef();

  const { pid } = useParams();
  const { elemId } = useParams();

  const username = useUserStore((state) => state.username)

  // a supprimer
  useEffect(() => {
    setPhotoArray([
      {url:k1, name:'k1'},
      {url:k2, name:'k2'},
      {url:k6, name:'k6'},
      {url:k7, name:'k7'},
      {url:k8, name:'k8'},
      {url:k9, name:'k9'},
      {url:k10, name:'k10'},
      {url:k11, name:'k11'},
      {url:k14, name:'k14'},
      {url:k15, name:'k15'},
      {url:k17, name:'k17'},
      {url:k20, name:'k20'},
      {url:k21, name:'k21'},
      {url:k22, name:'k22'},
      {url:k23, name:'k23'},
      {url:k24, name:'k24'},
      {url:k26, name:'k26'},
      {url:k28, name:'k28'},
      {url:k30, name:'k30'},
      {url:k32, name:'k32'},
      {url:k33, name:'k33'},
      {url:k34, name:'k34'},
      {url:k35, name:'k35'},
      {url:k36, name:'k36'},
      {url:k37, name:'k37'},
      {url:k38, name:'k38'},
      {url:k39, name:'k39'},
      {url:k40, name:'k40'},
      {url:k41, name:'k41'},
      {url:k42, name:'k42'},
      {url:k43, name:'k43'},
      {url:k44, name:'k44'},
      {url:k45, name:'k45'},
      {url:k46, name:'k46'},
      {url:k48, name:'k48'},
      {url:k49, name:'k49'},
      {url:k50, name:'k50'},
      {url:k502, name:'k50(2)'},
      {url:k503, name:'k50(3)'},
      {url:k51, name:'k51'},
      {url:k52, name:'k52'},
      {url:k53, name:'k53'},
      {url:k532, name:'k53(2)'},
      {url:k54, name:'k54'},
      {url:k542, name:'k54(2)'},
      {url:k55, name:'k55'},
      {url:k56, name:'k56'},
      {url:k57, name:'k57'},
      {url:k58, name:'k58'},
      {url:k59, name:'k59'},
      {url:k60, name:'k60'},
      {url:k61, name:'k61'},
      {url:k62, name:'k62'},
      {url:k63, name:'k63'},
      {url:k64, name:'k64'},
      {url:k65, name:'k65'},
      {url:k66, name:'k66'},
      {url:k68, name:'k68'},
      {url:k69, name:'k69'},
      {url:k70, name:'k70'},
      {url:k72, name:'k72'},
      {url:k75, name:'k75'},
      {url:k76, name:'k76'},
      {url:k77, name:'k77'},
      {url:k78, name:'k78'},
      {url:k79, name:'k79'},
      {url:k80, name:'k80'},
      {url:k81, name:'k81'},
      {url:k82, name:'k82'},
      {url:k83, name:'k83'},
      {url:k84, name:'k84'},
      {url:k85, name:'k85'},
      {url:k86, name:'k86'},
      {url:k87, name:'k87'},
      {url:k89, name:'k89'},
      {url:k91, name:'k91'},
      {url:k92, name:'k92'},
      {url:k93, name:'k93'},
      {url:k94, name:'k94'},
      {url:k100, name:'k100'},
      {url:k101, name:'k101'},
      {url:k102, name:'k102'},
      {url:k103, name:'k103'},
      {url:k104, name:'k104'},
      {url:k105, name:'k105'},
      {url:k106, name:'k106'}
    ])
  }, [])

  useEffect(() => {
    if (username !== '') {
      setConnected(true);
    }
    setLoading(true)
    setErrorMessage('')
    axios.get('https://cdgdev-local.azurewebsites.net/v1/Project', { params: { pid }})
    .then((res) => {
      setErrorMessage('')
      setLoading(false)
      setProjet(res.data.data)
    })
    .catch((error) => {
      console.log(error)
      setLoading(false)
      setErrorMessage("Erreur du chargement du projet. Veuillez rafraîchir la page.")
    });
  }, [])

  useEffect(() => {
    if (projet) { 
      useUserStore.setState({fontColor:projet.hexaText, bgColor:projet.hexaBackground, logoImage: projet.logoPath})
      map.current = new maplibregl.Map({
        container: mapContainer.current,
        style: 'https://api.maptiler.com/maps/dataviz/style.json?key=6lKsa6bzFsGKREIufiPE',
        center: [projet.center.lng, projet.center.lat],
        zoom: 10,
        attributionControl: false
        });
      map.current.on ('load', () => {
        // if (!map.current.getSource('projectsource')) {
        //   map.current.addSource('projectsource', {
        //     'type': 'geojson',
        //     'data': {
        //       'type': 'FeatureCollection',
        //       'features': projet.projectPlan.features
        //     }
        //   })
        // }
        if (!map.current.getSource('plansource')) {
          map.current.addSource('plansource', {
            'type': 'image',
            "url": planPNG,
            "coordinates": [
              [-72.543426475, 46.340457489],
              [-72.542090359, 46.340457489],
              [-72.542090359, 46.339477705],
              [-72.543426475, 46.339477705]
            ]
          })
        }
        if (!map.current.getLayer('planlayer')) {
          map.current.addLayer({
            "id": "planlayer",
            "source": "plansource",
            "type": "raster",
            "paint": {
                "raster-opacity": 0.55
            }
        });
        }
        if (!map.current.getLayer('planlayer')) {
          map.current.addControl(
            new maplibregl.GeolocateControl({
              positionOptions: {
                enableHighAccuracy: true
              },
              trackUserLocation: true
            })
          );
        }
        // if (!map.current.getLayer('projectlayer')) {
        //   map.current.addLayer({
        //     'id': 'projectlayer',
        //     'type': 'line',
        //     'source': 'projectsource',
        //     'layout':{},
        //     'paint': {
        //       'line-color': '#000000',
        //       'line-width': 2
        //     }
        //   })
        // }
      })
      if (projet.bounds && !elemId) {
        map.current.fitBounds(projet.bounds, {padding: 100});
      }
      if (elemId && projet.elements.length!==0) {
        const chosenElem = projet.elements.filter((elem) => elem.elementPID===elemId)
        map.current.flyTo({
              center:[chosenElem[0].longitude, chosenElem[0].latitude],
              essential:true,
              zoom:18
        })
      }
      if (projet.elements && projet.elements.length!==0) {
        projet.elements.forEach((element) => {
          const markerIcon = document.createElement('div');
          markerIcon.classList.add('book-icon-marker')
          const newmarker = new maplibregl.Marker(markerIcon, {
            anchor: 'bottom',
            offset: [0, 6]
          });
          newmarker.setLngLat([element.longitude, element.latitude]);
          const newpopup = new maplibregl.Popup({offset:25, className:'marker-popup'})
          const pictureObject = photoArray.filter((pic) => pic.name === element.photoURL)
          if (pictureObject.length) {
            newpopup.setHTML(`
                              <div class='marker-content-box'>
                                <img src=${pictureObject[0].url} alt='kiosque' class='kiosque-img'/>
                                <h6 class='kiosque-name'>${element.name}</h6>
                              </div>
                            `)
          } else {
            newpopup.setHTML(`
                              <div class='marker-content-box'>
                                <h6 class='kiosque-name'>${element.name}</h6>
                              </div>
                            `)
          }
          newmarker.setPopup(newpopup);
          newmarker.addTo(map.current);
          const markerRefObject = {markerRef:newmarker, markerIconRef:markerIcon, popupRef: newpopup, markerImgRef:element.photoURL, lat:element.latitude, lng:element.longitude, name: element.name};
          const isAlreadyIn = markersRefs.filter((marker) => (
            marker.lng === element.longitude && marker.lat === element.latitude
          ))
          if (!isAlreadyIn.length) {
            setMarkersRefs((prevState) => [...prevState, markerRefObject])
          }
          newpopup.on('open', () => {
            markerIcon.classList.add('book-icon-marker-focused')
          })
          newpopup.on('close', () => {
            markerIcon.classList.remove('book-icon-marker-focused')
          })
        })
      }
    }
  }, [projet])

  const searchItem = (item) => {
    if (!item.length) {
      setDataToDisplay([])
    } else {
      const filteredElements = projet.elements.filter((element) => element.name.toLowerCase().startsWith(item.toLowerCase()));
      setDataToDisplay([...filteredElements]);
    }
  }

  const goToData = (data) => {
    map.current.once('moveend', () => {
      const markerOfInterest = markersRefs.filter((marker) => (
        marker.lng === data.longitude && marker.lat === data.latitude
      ))
      markerOfInterest[0].popupRef.remove();
      const newpopup = new maplibregl.Popup({offset:25, className:'marker-popup'}).setText(markerOfInterest[0].name)
      const pictureObject = photoArray.filter((pic) => pic.name === markerOfInterest[0].markerImgRef)
      if (pictureObject.length) {
        newpopup.setHTML(`
                          <div class='marker-content-box'>
                            <img src=${pictureObject[0].url} alt='kiosque' class='kiosque-img'/>
                            <h6 class='kiosque-name'>${markerOfInterest[0].name}</h6>
                          </div>
                        `)
      } else {
        newpopup.setHTML(`
                          <div class='marker-content-box'>
                            <h6 class='kiosque-name'>${markerOfInterest[0].name}</h6>
                          </div>
                        `)
      }
      newpopup.on('open', () => {
        markerOfInterest[0].markerIconRef.classList.add('book-icon-marker-focused')
      })
      newpopup.on('close', () => {
          markerOfInterest[0].markerIconRef.classList.remove('book-icon-marker-focused')
      })
      markerOfInterest[0].markerRef.setPopup(newpopup);
      markerOfInterest[0].markerRef.addTo(map.current);
      markerOfInterest[0].markerRef.togglePopup();
    })
    map.current.flyTo({
      center:[data.longitude, data.latitude],
      essential:true,
      zoom:26
    })
    inputRef.current.value = '';
    navigate(`/${pid}/${data.elementPID}`, { replace:true})
    setDataToDisplay([]);
  }

  const backToMyProjects = () => {
    navigate('/mesprojets')
  }

  const backToProjects = () => {
    navigate('/')
  }

  return (
    <main className="chosen-project-container">
      {errorMessage || loading? 
        <div className="error-message-container">
          {errorMessage? <h2 className="error-message">{errorMessage}</h2>:null}
          {loading? <div className="loading-container">
                      <RotatingLines
                        strokeColor="#f64c72"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="40"
                      />
                      <h2 className="error-message">Chargement du projet en cours...</h2>
                    </div>
                    :
                    null}
        </div>
      : null}
      <div ref={mapContainer} style={{width: '100%', display:'flex', flex:'1'}} />
      {connected?
        <button className="back-to-projects-btn" type='button' onClick={backToMyProjects}>RETOUR À MES PROJETS</button> 
        : 
        <button className="back-to-projects-btn" type='button' onClick={backToProjects}>RETOUR AUX PROJETS</button> 
      }
      <div className="search-bar-container">
        <div className="search-bar-input-container">
          <input className="search-bar-input" placeholder="CHERCHER" ref={inputRef} onChange={(e) => searchItem(e.target.value)}/>
          <img src={searchIcon} alt='search icon' className="search-image"/>
        </div>
        <div className="search-results-container" style={{display: dataToDisplay.length>0?'flex':'none'}}>
          {dataToDisplay.length>0?
            dataToDisplay?.map((data, index) => <button key={index} className="search-result" type='button' onClick={() => goToData(data)}>{data.name}</button>)
            :
            null
          }
        </div>
      </div>
    </main>
  )
}

export default Projet;
