/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from 'axios';
import { RotatingLines } from "react-loader-spinner";
import InputColor from "react-input-color";
import useUserStore from "../useUserStore";
import "../connexion/Connexion.css";
import "./Nouveauprojet.css";
import uploadImageIcon from "../images/uploadImage.png";

function Nouveauprojet() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [projectName, setProjectName] = useState('');
  const [bgColor, setBgColor] = useState('');
  const [fontColor, setFontColor] = useState('');
  const [logoImage, setLogoImage] = useState('');

  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const hiddenFileInput = useRef(null);

  const onSubmit = () => {
    const sessionStorageInfo = JSON.parse(sessionStorage.getItem('user info'));
    const {token} = sessionStorageInfo.state;
    if (logoImage!=='') {
      const formdata =  new FormData();
      formdata.append('img', logoImage);
      setLoading(true)
      setErrorMessage('')
      axios.post('https://cdgdev-local.azurewebsites.net/v1/Image/add', formdata, { headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'multipart/form-data'} })
        .then((res) => {
          setErrorMessage('')
          setLoading(false)
          console.log(res.data)
          useUserStore.setState({fontColor:fontColor.hex, bgColor:bgColor.hex, projectName, logoImage:`https://cdgdev-local.azurewebsites.net/v1/Image/get/${res.data.imagePID}`})
          navigate('/nouveauprojet/ajoutplan')
        })
        .catch((error) => {
          console.log(error)
          setLoading(false)
          setErrorMessage("Erreur dans l'importation de l'image. Veuillez réessayer.")
        });
    } else {
      useUserStore.setState({fontColor:fontColor.hex, bgColor:bgColor.hex, projectName, logoImage})
      navigate('/nouveauprojet/ajoutplan')
    }
  };

  const uploadImage = () => {
    hiddenFileInput.current.click();
  };

  return (
    <main className="connexion-container">
      {errorMessage || loading? 
        <div className="error-message-container">
          {errorMessage? <h2 className="error-message">{errorMessage}</h2>:null}
          {loading? <div className="loading-container">
                      <RotatingLines
                        strokeColor="#f64c72"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="40"
                      />
                      <h2 className="error-message">Création du projet en cours...</h2>
                    </div>
                    :
                    null}
        </div>
      : null}
      <div className="connexion-sub-container">
        <div className="connexion-title-container">
          <h1 className="connexion-title">NOUVEAU PROJET</h1>
        </div>
        <form
          className="connexion-form"
          action="TO BE ADDED"
          method="post"
          onSubmit={handleSubmit(onSubmit)}
        >
          <input
            placeholder="NOM DU PROJET"
            type="text"
            {...register("nom", {
              required: "Un nom de projet est requis",
              maxLength: {
                value: 100,
                message:
                  "Votre nom de projet doit contenir 100 caractères au maximum",
              },
            })}
            aria-invalid={errors.nom ? "true" : "false"}
            className="connexion-input"
            onChange={(e) => setProjectName(e.target.value)}
          />
          {errors.nom && (
            <p role="alert" className="error-message">
              {errors.nom?.message}
            </p>
          )}
          <div className="newproject-input-container">
            <p className="newproject-input-text">COULEUR DU FOND</p>
            <InputColor
              initialValue="#99738E"
              onChange={setBgColor}
              placement="left"
              className="color-picker-container"
            />
          </div>
          <div className="newproject-input-container">
            <p className="newproject-input-text">COULEUR DU TEXTE</p>
            <InputColor
              initialValue="#212529"
              onChange={setFontColor}
              placement="left"
              className="color-picker-container"
            />
          </div>
          <div className="newproject-input-container">
            <p className="newproject-input-text">{logoImage? logoImage.name:'AJOUTEZ VOTRE LOGO'}</p>
            <button className="upload-btn" onClick={uploadImage} type="button">
              <img
                src={uploadImageIcon}
                alt="upload a media file"
                className="upload-image-icon"
              />
            </button>
            <input
              type="file"
              name="image"
              accept="image/*"
              onChange={(e) => setLogoImage(e.target.files[0])}
              style={{ display: "none" }}
              ref={hiddenFileInput}
            />
          </div>
          <button type="submit" className="connect-btn">
            Sauvegarder
          </button>
        </form>
      </div>
    </main>
  );
}

export default Nouveauprojet;
