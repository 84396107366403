/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import maplibregl from 'maplibre-gl';
import Map from 'react-map-gl';
import axios from 'axios';
import { RotatingLines } from  'react-loader-spinner'
import useUserStore from "../useUserStore";
import addProj from'../images/addProj.svg';
import deleteProj from '../images/deleteProj.svg';
import editProj from '../images/editProj.svg';
import './Projets.css';

function Mesprojets() {
  const navigate = useNavigate();

  const [projets, setProjets] = useState([]);
  const [displayCount, setDisplayCount] = useState(3);
  const [deleteProjectModal, setDeleteProjectModal] = useState('');

  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    useUserStore.setState({fontColor:'#000000', bgColor:'#ffffff', logoImage:''})
    const sessionStorageInfo = JSON.parse(sessionStorage.getItem('user info'));
    const {token} = sessionStorageInfo.state;
    setLoading(true)
    setErrorMessage('')
    axios.get('https://cdgdev-local.azurewebsites.net/v1/Project/user/all', { headers: { 'Authorization': `Bearer ${token}`}})
    .then((res) => {
      setErrorMessage('')
      setLoading(false)
      setProjets(res.data.data)
    })
    .catch((error) => {
      console.log(error, error.response.data.message)
      setLoading(false)
      setErrorMessage("Erreur dans la récupération de vos projets. Veuillez rafraîchir la page.")
    });
  }, [])

  const addProject = () => {
    navigate('/nouveauprojet')
  }

  const openDeleteModal = (projectId) => {
    setDeleteProjectModal(projectId)
  }

  const deleteProject = (projectToDelete) => {
    const sessionStorageInfo = JSON.parse(sessionStorage.getItem('user info'));
    const {token} = sessionStorageInfo.state;
    setLoading(true)
    setErrorMessage('')
    axios.delete('https://cdgdev-local.azurewebsites.net/v1/Project', { params: { pid: projectToDelete.projectPID}, headers: { 'Authorization': `Bearer ${token}`} })
      .then((res) => {
        setErrorMessage('')
        setLoading(false)
        console.log(res.data.data)
        const newProjectsArray = projets.filter((proj) => proj !== projectToDelete)
        setProjets(newProjectsArray);
        useUserStore.setState({projets:[...newProjectsArray]})
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
        setErrorMessage("Erreur de suppression du projet. Veuillez réessayer.")
      });
  }

  const doNotDelete = () => {
    setDeleteProjectModal('')
  }

  const editProject = (projToEdit) => {
    navigate(`/mesprojets/${projToEdit.projectPID}/miseajour`, {
    })
  }

  const goToProject = (projet) => {
    navigate(`/${projet.projectPID}`)
  }

  const loadMoreProjects = () => {
    setDisplayCount(displayCount + 3);
  };

  const seePublicProjects = () => {
    navigate('/');
  }

  return (
    <main className="connexion-container">
      {errorMessage || loading? 
        <div className="error-message-container">
          {errorMessage? <h2 className="error-message">{errorMessage}</h2>:null}
          {loading? <div className="loading-container">
                      <RotatingLines
                        strokeColor="#f64c72"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="40"
                      />
                      <h2 className="error-message">Chargement de vos projets en cours...</h2>
                    </div>
                    :
                    null}
        </div>
      : null}
      <button className="see-projects-link-btn" type='button' onClick={seePublicProjects}>VOIR TOUS LES PROJETS</button>
      <button type='button' onClick={addProject} className='proj-btn'><img src={addProj} alt='add a project' className="addProj-image"/></button>
      <div className="my-projets-container">
        <div className="projets-title-container">
          <div className="projets-title-sub-container">
            <h1 className="projets-title">MES PROJETS</h1>
          </div>
        </div>
        <div className="display-projects-container">
          {projets?.length !==0 ?
              projets?.slice(0, displayCount).map((projet, index) => 
                                        // eslint-disable-next-line react/no-array-index-key
                                        <div key={index} className="single-project-container">
                                          <div className="single-project-map-container">
                                            <Map  mapLib={maplibregl}
                                              initialViewState={{
                                                longitude: projet.center.lng,
                                                latitude: projet.center.lat,
                                                zoom: 10,
                                                minZoom: 10
                                              }}
                                              mapStyle='https://api.maptiler.com/maps/dataviz/style.json?key=6lKsa6bzFsGKREIufiPE'
                                              attributionControl= {false}
                                              style={{borderRadius:'2rem'}}
                                            />
                                          </div>
                                          <button className="single-project-btn" type='button' onClick={() => goToProject(projet)}>{projet.name}</button>
                                          <div className="my-project-icons-container">
                                            <div className="delete-project-container">
                                              <button type='button' onClick={() => editProject(projet)} className='delete-proj-btn'><img src={editProj} className='deleteProj-image' alt='delete project'/></button>
                                            </div>
                                            <div className="delete-project-container">
                                              <button type='button' onClick={() => openDeleteModal(projet.projectPID)} className='delete-proj-btn'><img src={deleteProj} className='deleteProj-image' alt='delete project'/></button>
                                            </div>
                                            {deleteProjectModal===projet.projectPID?
                                            <div className="delete-project-modal">
                                              <h5 className="delete-project-modal-text">Voulez-vous vraiment supprimer ce projet?</h5>
                                              <div className="delete-project-modal-sub-container">
                                                <button className="delete-project-modal-btn" onClick={() => deleteProject(projet)} type='button'>OUI</button>
                                                <button className="delete-project-modal-btn" onClick={doNotDelete} type='button'>NON</button>
                                              </div>
                                            </div>
                                            :
                                            null
                                            }
                                          </div>
                                        </div>)
          :
          <div className="no-proj-box">
            <h2 className="no-proj-text">AUCUN PROJET</h2>
          </div>
          }
        </div>
        {displayCount < projets.length && (
            <button onClick={loadMoreProjects} type='button' className="see-more-projects-btn">VOIR PLUS DE PROJETS</button>
           )}
      </div>
    </main>
  );
}

export default Mesprojets;