import React from "react";
import "./Footer.css";
import kodaLogo from "./images/kodaLogo.png";

function Footer() {
  return (
    <footer className="footer-container">
      <h6 className="footer-text">© LOCAL 2022 - TOUS DROITS RÉSERVÉS</h6>
      <h6 className="footer-text">
        UNE PRODUCTION DE
        <img src={kodaLogo} alt="koda logo" className="koda-logo" />
      </h6>
    </footer>
  );
}

export default Footer;
