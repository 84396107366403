/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable prefer-arrow-callback */
import React, { useEffect, useRef, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import maplibregl from 'maplibre-gl';
import axios from 'axios';
import { RotatingLines } from "react-loader-spinner";
import useUserStore from "../useUserStore";
import addElementIcon from "../images/addProj.svg";
import deleteElementIcon from "../images/deleteProj.svg";
import bookIcon from "../images/book.svg";

function Positionnerelements() {
  const navigate = useNavigate();

  const projectPlan = useUserStore((state) => state.projectPlan);
  const projectBounds = useUserStore((state) => state.projectBounds);
  const projectName = useUserStore((state) => state.projectName);
  const projetBgColor = useUserStore((state) => state.bgColor);
  const projetFontColor = useUserStore((state) => state.fontColor);
  const projectLogo = useUserStore((state) => state.logoImage);

  const [elements, setElements] = useState([]);
  const [elementsToSend, setElementsToSend] = useState([]);
  const [addingElement, setAddingElement] = useState(false);
  const [placingMarker, setPlacingMarker] = useState(false);

  const [currentName, setCurrentName] = useState('');
  const [currentMarkerCoords, setCurrentMarkerCoords] = useState(undefined);
  const [center, setCenter] = useState(undefined);
  const [markers, setMarkers] = useState([]);
  const [photoURL, setPhotoURL] = useState('')

  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  
  let elemNum = 0;

  const mapContainer = useRef();
  const map = useRef();
  const inputRef = useRef();
  const photoURLRef = useRef();
  const markerRef = useRef();

  const addMarkerCallbackElement = useCallback((e) => {
    const markerIcon = document.createElement('div');
    markerIcon.classList.add('book-icon-marker')
    const marker = new maplibregl.Marker(markerIcon, {
      anchor: 'bottom',
      offset: [0, 6]
    });
    markerRef.current = marker;
    marker.setLngLat(e.lngLat);
    marker.addTo(map.current);
    setCurrentMarkerCoords({lat:e.lngLat.lat, lng:e.lngLat.lng});
    setPlacingMarker(false);
    setMarkers((prevState) => [...prevState, marker])
  },[])

  useEffect(() => {
    if (projectName==='') {
      navigate('/nouveauprojet')
    }
    map.current = new maplibregl.Map({
      container: mapContainer.current,
      style: 'https://api.maptiler.com/maps/dataviz/style.json?key=6lKsa6bzFsGKREIufiPE',
      center: [-73.573916, 45.490176],
      zoom: 10,
      attributionControl: false
      });
    map.current.on ('load', () => {
      if (!map.current.getSource('projectsource')) {
        map.current.addSource('projectsource', {
          'type': 'geojson',
          'data': projectPlan
        })
      }
      if (!map.current.getLayer('projectlayer')) {
        map.current.addLayer({
          'id': 'projectlayer',
          'type': 'line',
          'source': 'projectsource',
          'layout':{},
          'paint': {
            'line-color': '#000000',
            'line-width': 2
          }
        })
        map.current.fitBounds(projectBounds, {padding: 100});
        const centerOfBounds = {lng:projectBounds[0], lat:projectBounds[1]}
        setCenter(centerOfBounds)
      }
    })
  }, []);

  useEffect(() => {
    if (placingMarker===true) {
      map.current.on('click', addMarkerCallbackElement)
    } else {
      map.current.off('click', addMarkerCallbackElement)
    }
  }, [placingMarker])

  const addMarker = () => {
    if (!addingElement) {
      setAddingElement(true)
      setPlacingMarker(true)
    }
  }

  const addElement = () => {
    if (currentName!=='' && !placingMarker) {
      const popup = new maplibregl.Popup({offset:25, className:'marker-popup'})
      if (photoURL!=='') {
        popup.setHTML(`
                          <div class='marker-content-box'>
                            <img src=${photoURL} alt='kiosque' class='kiosque-img'/>
                            <h6 class='kiosque-name'>${currentName}</h6>
                          </div>
                        `)
      } else {
        popup.setHTML(`
                          <div class='marker-content-box'>
                            <h6 class='kiosque-name'>${currentName}</h6>
                          </div>
                        `)
      }
      markerRef.current.setPopup(popup);
      setElements([...elements, {name:currentName, photoURL, lat:currentMarkerCoords.lat, lng:currentMarkerCoords.lng, markerRef:markerRef.current}]);
      setElementsToSend([...elementsToSend, {name:currentName, photoURL, lat:currentMarkerCoords.lat, lng:currentMarkerCoords.lng}])
      inputRef.current.value = '';
      setAddingElement(false)
      setPlacingMarker(false)
      setCurrentName('')
      setPhotoURL('')
    }
  }

  const deleteElement = (elem) => {
    const markerToRemove = markers.filter((marker) => marker===elem.markerRef);
    markerToRemove[0].remove();
    const newElementsArray = elements.filter((element) => element!==elem);
    const newElementsToSendArray = elementsToSend.filter((element) => element.name!==elem.name);
    setElements(newElementsArray);
    setElementsToSend(newElementsToSendArray)
  }

  const saveProjet = () => {
    const newProject = {elements: elementsToSend, center, bounds:projectBounds, plan:projectPlan, name: projectName, hexaBackground:projetBgColor, hexaText:projetFontColor, logoPath:projectLogo }
    const sessionStorageInfo = JSON.parse(sessionStorage.getItem('user info'));
    const {token} = sessionStorageInfo.state;
    setLoading(true)
    setErrorMessage('')
    axios.post('https://cdgdev-local.azurewebsites.net/v1/Project', newProject, { headers: { 'Authorization': `Bearer ${token}`}})
      .then((res) => {
        setErrorMessage('')
        setLoading(false)
        console.log(res)
        navigate('/mesprojets')
      })
      .catch((error) => {
        console.log(error, error.response.data.message)
        setLoading(false)
        setErrorMessage("Erreur dans la création du projet. Veuillez réessayer.")
      });
  }

  return (
    <main className="chosen-project-container">
      {errorMessage || loading? 
        <div className="error-message-container">
          {errorMessage? <h2 className="error-message">{errorMessage}</h2>:null}
          {loading? <div className="loading-container">
                      <RotatingLines
                        strokeColor="#f64c72"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="40"
                      />
                      <h2 className="error-message">Sauvegarde du projet en cours...</h2>
                    </div>
                    :
                    null}
        </div>
      : null}
      <div ref={mapContainer} style={{width: '100%', display:'flex', flex:'1'}} />
      <div className="elements-container">
        <div className="empty-elements-container">
          <h1 className="add-elem-title">CRÉER UN ÉLÉMENT</h1>
          <button type='button' onClick={addMarker} className='elem-btn'><img src={addElementIcon} alt='add an element' className="addElem-image-large"/></button>
        </div>
        <div className="instruction-container" style={{display:addingElement?'flex':'none'}}>
          <p className="instruction-text">DONNEZ UN NOM À L'ÉLÉMENT, SÉLECTIONNEZ SA NATURE, CLIQUEZ SUR LA CARTE POUR LE PLACER À LA POSITION DÉSIRÉE, PUIS AJOUTEZ L'ÉLÉMENT À VOTRE COLLECTION VIA LE BOUTON      <img src={addElementIcon} alt='add an element' className="addElem-image-small"/></p>
        </div>
        <div className="addElem-input-container" style={{display:addingElement?'flex':'none'}}>
            <div className="addElem-input-sub-container">
              <input ref={inputRef} type='text' placeholder="Nom de l'élément" onChange={(e) => setCurrentName(e.target.value)} className='addElem-input'/>
              <input ref={photoURLRef} type='text' placeholder="Url de la photo" onChange={(e) => setPhotoURL(e.target.value)} className='addElem-input'/>
            </div>
            <button type='button' onClick={addElement} className='elem-btn'><img src={addElementIcon} alt='add an element' className="addElem-image-large"/></button>
        </div>
        <div className="elements-sub-container">
          {elements?.length!==0?
            elements?.map((element) => {
              elemNum += 1;
              return (
              <div key={elemNum} className='single-element-container pink-shadow'>
                <h2 className="elem-num">{elemNum}</h2>
                <img src={bookIcon} alt='book icon' className="book-icon"/>
                <h4 className="elem-input">{element.name}</h4>
                <div className="delete-project-container">
                  <button type='button' onClick={() => deleteElement(element)} className='delete-proj-btn'><img src={deleteElementIcon} className='deleteProj-image' alt='delete element'/></button>
                </div>
              </div>
              )
            })
          :
          <div className="empty-elements-container">
            <h2 className="no-elem-text">AUCUN POINT D'INTÉRÊT AJOUTÉ</h2>
          </div>
          }
        </div>
          {elements?.length!==0?
            <button type="submit" className="connect-btn" onClick={saveProjet}>
              Sauvegarder Projet
            </button>
          :
            null
          }
      </div>
    </main>
  );
}

export default Positionnerelements;
