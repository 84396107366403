/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Connexion from "./connexion/Connexion";
import Inscription from "./connexion/Inscription";
import Oublimotdepasse from "./connexion/Oublimotdepasse";
import Reinitialisationmotdepasse from "./connexion/Reinitialisationmotdepasse";
import Projets from "./projets/Projets";
import Mesprojets from "./projets/Mesprojets";
import Projet from "./projets/Projet";
import Miseajourprojet from "./projets/Miseajourprojet";
import Nouveauprojet from "./nouveauprojet/Nouveauprojet";
import Ajoutplan from "./nouveauprojet/Ajoutplan";
import Configuration from "./nouveauprojet/Configuration";
import Positionnerelements from "./nouveauprojet/Positionnerelements";
import PrivateRoutes from "./utils/PrivateRoutes";
import "./App.css";

function App() {
  return (
    <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Projets />} />
            <Route path="/:pid" element={<Projet />} />
            <Route path="/:pid/:elemId" element={<Projet />} />
          <Route path="/connexion" element={<Connexion />} />
          <Route path="/inscription" element={<Inscription />} />
          <Route path="/oublimotdepasse" element={<Oublimotdepasse />} />
          <Route
            path="/nouveaumotdepasse/:token"
            element={<Reinitialisationmotdepasse />}
          />
          <Route element={<PrivateRoutes/>}>
            <Route path="/mesprojets" element={<Mesprojets />} />
            <Route path="/mesprojets/:pid/miseajour" element={<Miseajourprojet />} />
            <Route path="/nouveauprojet" element={<Nouveauprojet />} />
            <Route path="/nouveauprojet/ajoutplan" element={<Ajoutplan />} />
              <Route path="/nouveauprojet/ajoutplan/configuration" element={<Configuration />} />
                <Route
                  path="/nouveauprojet/ajoutplan/configuration/positionnerelements"
                  element={<Positionnerelements />}
                />
          </Route>
        </Routes>
        <Footer />
    </BrowserRouter>
  );
}

export default App;
