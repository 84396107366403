/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-props-no-spreading */
import React , { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { RotatingLines } from "react-loader-spinner";
import axios from 'axios';
import useUserStore from "../useUserStore";
import "./Connexion.css";

function Inscription() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const username = useUserStore((state) => state.username)

  useEffect(() => {
    if (username!=='') {
      navigate('/mesprojets')
    }
  })
 
  const onSubmit = (formdata) => {
    setLoading(true)
    setErrorMessage('')
    axios.post('https://cdgdev-local.azurewebsites.net/v1/Identity/create', formdata)
      .then((res) => {
        console.log(res)
        setErrorMessage('')
        setLoading(false)
        navigate('/connexion')
      })
      .catch((error) => {
        console.log(error, error.response.data.message)
        setLoading(false)
        setErrorMessage("Erreur d'inscription. Veuillez réessayer.")
      });
  };

  return (
    <main className="connexion-container">
      {errorMessage || loading? 
        <div className="error-message-container">
          {errorMessage? <h2 className="error-message">{errorMessage}</h2>:null}
          {loading? <div className="loading-container">
                      <RotatingLines
                        strokeColor="#f64c72"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="40"
                      />
                      <h2 className="error-message">Inscription en cours...</h2>
                    </div>
                    :
                    null}
        </div>
      : null}
      <div className="connexion-sub-container">
        <div className="connexion-title-container">
          <h1 className="connexion-title">S'INSCRIRE</h1>
        </div>
        <form
          className="connexion-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <input
            placeholder="NOM D'UTILISATEUR"
            type="text"
            {...register("name", {
              required: "Un nom d'utilisateur est requis",
              maxLength: {
                value: 20,
                message:
                  "Votre nom d'utilisateur doit contenir 20 caractères au maximum",
              },
              minLength: {
                value: 3,
                message:
                  "Votre nom d'utilisateur doit contenir 3 caractères au minimum",
              },
            })}
            aria-invalid={errors.name ? "true" : "false"}
            className="connexion-input"
          />
          {errors.name && (
            <p role="alert" className="error-message">
              {errors.name?.message}
            </p>
          )}
          <input
            placeholder="COURRIEL"
            type="text"
            {...register("email", {
              required: "Veuillez entrer votre adresse courriel",
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Veuillez entrez une adresse courriel valide",
              },
            })}
            aria-invalid={errors.email ? "true" : "false"}
            className="connexion-input"
          />
          {errors.email && (
            <p role="alert" className="error-message">
              {errors.email?.message}
            </p>
          )}
          <input
            placeholder="MOT DE PASSE"
            type="password"
            {...register("password", {
              required: "Veuillez choisir un mot de passe",
              minLength: {
                value: 8,
                message:
                  "Votre mot de passe doit contenir 8 caractères au minimum",
              },
              maxLength: {
                value: 20,
                message:
                  "Votre mot de passe doit contenir 20 caractères au maximum",
              },
            })}
            aria-invalid={errors.password ? "true" : "false"}
            className="connexion-input"
          />
          {errors.password && (
            <p role="alert" className="error-message">
              {errors.password?.message}
            </p>
          )}
          <button type="submit" className="connect-btn">
            S'inscrire
          </button>
        </form>
        <Link to="/connexion" className="connexion-link">
          Se connecter
        </Link>
      </div>
    </main>
  );
}

export default Inscription;
