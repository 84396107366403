/* eslint-disable import/no-extraneous-dependencies */
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

const initialState = {
  username:'',
  token:'',
  useremail:'',
  projets: [],
  projectName:'',
  bgColor:'#ffffff',
  fontColor:'#000000',
  logoImage:'',
  projectPlan:undefined,
  projectBounds:undefined,
}

const useUserStore = create(
  persist((set) => ({
    ...initialState,
    reset: () => {set(initialState)},
    updateProjects: (projet) => {
      set((state) => ({
        projets: [...state.projets, projet]
      }));
    },
  }),
  {
    name:'user info',
    partialize: (state) => ({username: state.username, token: state.token}),
    storage: createJSONStorage(() => sessionStorage),
  },
))

export default useUserStore;