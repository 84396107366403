/* eslint-disable linebreak-style */
/* eslint-disable max-len */
/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import useUserStore from "../useUserStore";
import "../connexion/Connexion.css";
import "./Nouveauprojet.css";
import Expand from '../images/expand.png';
import Retract from '../images/retract.png';

function Configuration() {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [selectedLiaison, setSelectedLiaison] = useState('TYPE DE LIAISON');
  const [selectedHost, setSelectedHost] = useState('');
  const [selectedNom, setSelectedNom] = useState('');
  const [selectedMdp, setSelectedMdp] = useState('');
  const [formData, setFormData] = useState({liaison:'', host:'', nom:'', mdp:''});

  const [extendedFormDisplay, setExtendedFormDisplay] = useState('none');
  const [liaisonDisplay, setLiaisonDisplay] = useState('none');
  
  const [displayLiaisonError, setDisplayLiaisonError] = useState(false);
  const liaisonErrorMessage = 'Veuillez choisir un type de liaison';

  const projectName = useUserStore((state) => state.projectName)

  useEffect(()=> {
    if (projectName==='') {
      navigate('/nouveauprojet')
    }
  }, [])

  useEffect(() => {
    if (selectedLiaison==='BASE DE DONNÉES') {
      setFormData({
        liaison: selectedLiaison,
        host: selectedHost,
        nom: selectedNom,
        mdp: selectedMdp
      })
    } else if (selectedLiaison==='MANUEL') {
      setFormData({liaison: selectedLiaison})
    }
  }, [selectedLiaison, selectedHost, selectedNom, selectedMdp]);

  useEffect(() => {
    setDisplayLiaisonError(false)
    if (selectedLiaison==='BASE DE DONNÉES') {
      // à réactiver suivant implémentation option base de données
      // setExtendedFormDisplay('flex');
      setLiaisonDisplay('none');
    } else {
      setExtendedFormDisplay('none');
      setLiaisonDisplay('none');
    }
  }, [selectedLiaison])

  const showLiaisonChoices = () => {
    if (liaisonDisplay==='none') {
      setLiaisonDisplay('flex')
    } else {
      setLiaisonDisplay('none')
    }
  }

  const onSubmit = () => {
    if (selectedLiaison==='TYPE DE LIAISON') {
      setDisplayLiaisonError(true);
    } else if (selectedLiaison==='BASE DE DONNÉES') {
      // à modifier une fois l'option implémentée
      console.log(formData);
    } else if (selectedLiaison==='MANUEL') {
      useUserStore.setState({Configuration:'MANUEL'})
      navigate('/nouveauprojet/ajoutplan/configuration/positionnerelements')
    }
  };

  return (
    <main className="connexion-container">
      <div className="connexion-sub-container">
        <div className="connexion-title-container">
          <h1 className="connexion-title">CONFIGURATION</h1>
        </div>
        <form
          className="connexion-form"
          action="TO BE ADDED"
          method="post"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="select-container" onClick={showLiaisonChoices} onKeyDown={showLiaisonChoices} role='button' tabIndex={0}>
            <input
              placeholder={selectedLiaison}
              type="text"
              {...register("liaison")}
              aria-invalid={errors.liaison ? "true" : "false"}
              className="artificialSelect"
            />
            <button className="select-btn" type="button">
              <img
                src={liaisonDisplay==='none'? Expand:Retract}
                alt="select option icon"
                className="select-icon"
              />
            </button>
          </div>
          <div className="select-modal" style={{display:liaisonDisplay}}>
            <button /* onClick={() => setSelectedLiaison('BASE DE DONNÉES')} */ type='button' className="select-option">BASE DE DONNÉES (BIENTÔT DISPONIBLE!)</button>
            <button onClick={() => setSelectedLiaison('MANUEL')} type='button' className="select-option">MANUEL</button>
          </div>
          {displayLiaisonError?
            <p role="alert" className="error-message">
              {liaisonErrorMessage}
            </p>
            :
            undefined
          }
          <div style={{display:extendedFormDisplay}} className='optional-form'>
            <input
              placeholder="HÔTE"
              type="text"
              {...register("host", selectedLiaison==='BASE DE DONNÉES'?{
                required: "Veuillez entrer l'adresse de l'hôte",
                }:{
                required: false
                }
              )}
              aria-invalid={errors.host ? "true" : "false"}
              className="connexion-input"
              onChange={(e) => setSelectedHost(e.target.value)}
            />
            {errors.host && (
              <p role="alert" className="error-message">
                {errors.host?.message}
              </p>
            )}
            <input
              placeholder="UTILISATEUR"
              type="text"
              {...register("nom", selectedLiaison==='BASE DE DONNÉES'?{
                required: "Veuillez entrer votre nom d'utilisateur",
                maxLength: {
                  value: 20,
                  message:
                    "Votre nom d'utilisateur doit contenir 20 caractères au maximum",
                },
                minLength: {
                  value: 3,
                  message:
                    "Votre nom d'utilisateur doit contenir 3 caractères au minimum",
                },
                }:{
                  required:false
              })}
              aria-invalid={errors.nom ? "true" : "false"}
              className="connexion-input"
              onChange={(e) => setSelectedNom(e.target.value)}
            />
            {errors.nom && (
              <p role="alert" className="error-message">
                {errors.nom?.message}
              </p>
            )}
            <input
              placeholder="MOT DE PASSE"
              type="password"
              {...register("mdp", selectedLiaison==='BASE DE DONNÉES'?{
                required: "Veuillez entrer votre mot de passe",
                minLength: {
                  value: 8,
                  message: "Votre mot de passe contient 8 caractères au minimum",
                },
                maxLength: {
                  value: 20,
                  message: "Votre mot de passe contient 20 caractères au maximum",
                },
                }:{
                  required:false
              })}
              aria-invalid={errors.mdp ? "true" : "false"}
              className="connexion-input"
              onChange={(e) => setSelectedMdp(e.target.value)}
            />
            {errors.mdp && (
              <p role="alert" className="error-message">
                {errors.mdp?.message}
              </p>
            )}
            {/* Ajouter affichage d'erreur liée au mauvais mot de passe suite au retour de la requête API */}
          </div>
          <button type="submit" className="connect-btn">
            Sauvegarder
          </button>
        </form>
      </div>
    </main>
  );
}

export default Configuration;
