/* eslint-disable func-names */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable spaced-comment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import maplibregl from 'maplibre-gl';
import * as turf from '@turf/turf';
import useUserStore from "../useUserStore";
import { handleFile } from "../utils/utilsFunctions";

function Ajoutplan() {
  const navigate = useNavigate();
  const [plan, setPlan] = useState(undefined);
  const [parsedPlan, setParsedPlan] = useState(undefined)
  const [bounds, setBounds] = useState(undefined)
  const [errorMessage, setErrorMessage] = useState('');

  const hiddenFileInput = useRef(null);
  const mapContainer = useRef();
  const map = useRef(null);

  const projectName = useUserStore((state) => state.projectName)

  // A MODIFIER POUR ACCOMODER IMAGE GEOREFERENCEE COMME PLAN AUSSI

  useEffect(()=> {
    if (projectName==='') {
      navigate('/nouveauprojet')
    }
  }, [])

  useEffect(() => {
    if (map.current) return;
    map.current = new maplibregl.Map({
      container: mapContainer.current,
      style: 'https://api.maptiler.com/maps/dataviz/style.json?key=6lKsa6bzFsGKREIufiPE',
      center: [-73.573916, 45.490176],
      zoom: 10
      });
  })

  useEffect(() => {
    if (plan!==undefined) {
      handleFile(plan, setParsedPlan);
    }
  }, [plan])

  useEffect(() => {
    if (parsedPlan!==undefined) {
      if (map.current.getSource('projectsource') && map.current.getLayer('projectlayer')) {
        map.current.removeLayer('projectlayer')
        map.current.removeSource('projectsource')
        setErrorMessage('')
      }
      if (!map.current.getSource('projectsource')) {
          map.current.addSource('projectsource', {
            'type': 'geojson',
            'data': parsedPlan
          });
      }
      if (!map.current.getLayer('projectlayer')) {
            map.current.addLayer({
              'id': 'projectlayer',
              'type': 'line',
              'source': 'projectsource',
              'layout':{
              },
              'paint': {
                'line-color': '#000000',
                'line-width': 2
              }
            })
      }

      // if (!map.current.getLayer('poi-labels')) {
      //   map.current.addLayer({
      //     'id': 'poi-labels',
      //     'type': 'symbol',
      //     'source': 'projectsource',
      //     'layout': {
      //       'text-field': ['get', 'Nom'],
      //       'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
      //       'text-justify': 'auto',
      //     }
      //   });
      // }
            
      if (map.current.getSource('projectsource') && map.current.getLayer('projectlayer')) {
        try {
          const bbox = turf.bbox({
            type: 'FeatureCollection',
            features: parsedPlan.features
          });
          map.current.fitBounds(bbox, {padding: 100});
          setBounds(bbox);
        } catch(err) {
          console.log(err)
          setErrorMessage("Votre plan ne possède pas une structure valide.")
        }
      }
    }
  }, [parsedPlan])

  const uploadPlan = () => {
    hiddenFileInput.current.click();
  };

  const submitPlan = () => {
    useUserStore.setState({projectPlan:parsedPlan, projectBounds:bounds});
    navigate('/nouveauprojet/ajoutplan/configuration')
  }

  return (
    <main className="chosen-project-container">
      <div className="add-plan-container" style={{display:parsedPlan!==undefined&&!errorMessage?'flex':'none'}}>
          <h1 className='add-plan-title'>{parsedPlan?.name}</h1>
          <div className="add-plan-input-container"  onClick={submitPlan} onKeyDown={submitPlan} role="button" tabIndex="0">
              <button className="add-plan-btn" type="button">Sauvegarder</button>
          </div>
      </div>
      <div className="add-plan-container" style={{display:parsedPlan!==undefined&&errorMessage?'flex':'none'}}>
        <h1 className='add-plan-title'>AJOUTER UN PLAN</h1>
        <div className="add-plan-input-container"  onClick={uploadPlan} onKeyDown={uploadPlan} role="button" tabIndex="0">
          <button className="add-plan-btn" type="button">Fichier</button>
          <input
            type="file"
            name="file"
            accept=".geojson"
            onChange={(e) => {
              setPlan(e.target.files[0]);
            }}
            style={{ display: "none" }}
            ref={hiddenFileInput}
          />
        </div>
        <h2 className="error-message">{errorMessage}</h2>
      </div>
      <div className="add-plan-container" style={{display:!parsedPlan?'flex':'none'}}>
        <h1 className='add-plan-title'>AJOUTER UN PLAN</h1>
        <div className="add-plan-input-container"  onClick={uploadPlan} onKeyDown={uploadPlan} role="button" tabIndex="0">
            <button className="add-plan-btn" type="button">Fichier</button>
            <input
              type="file"
              name="file"
              accept=".geojson"
              onChange={(e) => {
                setPlan(e.target.files[0]);
              }}
              style={{ display: "none" }}
              ref={hiddenFileInput}
            />
        </div>
      </div>
      <div ref={mapContainer} style={{width: '100%', display:'flex', flex:'1'}} />
    </main>
  )
}

export default Ajoutplan;
