import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useUserStore from "../useUserStore";

function PrivateRoutes() {
    const username = useUserStore((state) => state.username)
    return (
        username!==''? <Outlet/> : <Navigate to='/connexion'/>
    )
}

export default PrivateRoutes;