import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// eslint-disable-next-line import/no-extraneous-dependencies
import CssFilterConverter from 'css-filter-converter';
import useUserStore from "./useUserStore";
import "./Header.css";
import connectedIcon from "./images/connectedIcon.png";
import disconnectIcon from "./images/disconnectIcon.png";
import logo from './images/logo.png';

function Header() {
  const navigate = useNavigate();

  const [connected, setConnected] = useState(false);
  const [displayDisconnectOption, setDisplayDisconnectOption] = useState('none');

  const username = useUserStore((state) => state.username)
  const reset = useUserStore((state) => state.reset);

  const fontColor = useUserStore((state) => state.fontColor);
  const bgColor = useUserStore((state) => state.bgColor);
  const logoImage = useUserStore((state) => state.logoImage);

  const [newLogo, setNewLogo] = useState('');
  const [filterFontColor, setFilterFontColor] = useState('invert(46%) sepia(93%) saturate(2528%) hue-rotate(318deg) brightness(97%) contrast(99%)');

  useEffect(() => {
    if (username !== '') {
        setConnected(true);
    }
  }, [username])

  useEffect(() => {
    if (fontColor!=='#000000') {
      const fontResult = CssFilterConverter.hexToFilter(fontColor).color;
      setFilterFontColor(fontResult);
    }
  }, [fontColor])

  useEffect(() => {
    setNewLogo(logoImage)
  }, [logoImage])

  const connect = () => {
    navigate('/connexion')
  }

  const disconnect = () => {
    setDisplayDisconnectOption('none')
    setConnected(false);
    useUserStore.persist.clearStorage();
    reset();
    navigate('/connexion');
  }

  const showLiaisonChoices = () => {
    if (displayDisconnectOption==='none') {
      setDisplayDisconnectOption('flex')
    } else {
      setDisplayDisconnectOption('none')
    }
  }

  const goToProjects = () => {
    useUserStore.setState({fontColor:'#000000', bgColor:'#ffffff'})
    if (connected) {
      navigate('/mesprojets')
    } else {
      navigate('/')
    }
  }

  return (
    <header className="header-container" style={{background:bgColor}}>
      <img  src={newLogo!==''?newLogo:logo} 
            className="logo-image" 
            alt="logo" 
            onClick={goToProjects} 
            onKeyDown={showLiaisonChoices} 
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
            role='button' 
            tabIndex={0} 
      />
      {connected ? (
        <div className="header-connected-container">
          <h3 className="connected-text" style={{color:fontColor}}>{username}</h3>
          <img
            src={connectedIcon}
            alt="connected-icon"
            className="connected-icon"
            onClick={showLiaisonChoices}
            onKeyDown={showLiaisonChoices} 
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
            role='button' 
            tabIndex={0} 
            style={{filter:filterFontColor}}
          />
          <div className='disconnect-container' style={{display:displayDisconnectOption, background:bgColor, borderColor:bgColor}}>
            <img src={disconnectIcon} alt='disconnect icon' className="disconnect-icon" style={{filter:filterFontColor}}/>
            <button type='button' onClick={disconnect} className='disconnect-btn' style={{color: fontColor==='#000000'? '#f64c72': fontColor}}>DÉCONNEXION</button>
          </div>
        </div>
      ) : (
        <button className="header-btn" type='button' onClick={connect}>Se connecter</button>
      )}
    </header>
  );
}

export default Header;
